<template lang="html">
  <div class="heading" :style="styleHeading">
    <h1 v-if="hType === 1">{{ text }}</h1>
    <h2 v-else-if="hType === 2">{{ text }}</h2>
    <h3 v-else-if="hType === 3">{{ text }}</h3>
    <h4 v-else-if="hType === 4">{{ text }}</h4>
  </div>
</template>

<script>
export default {
  name: 'Heading',
  props: {
    text: {
      type: String,
      required: true,
    },
    hType: {
      type: Number,
      required: true,
    },
    textAlign: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      styleHeading: { 'text-align': this.textAlign || 'left' },
    };
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
